import React, { useState, useEffect } from "react";

// External
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

// Material-UI
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

// Internal
import CustomTextField from "@Components/CustomTextField";
import CustomButton from "@Components/CustomButton";
import Logo from "./Logo";
import baseSchema from "../../../validation/BaseValidation";
import Utils from "sccUtils";
import log from "loglevel";
import Language from "sccLanguage";

//return back to homepage
const goToTitan = (e) => {
  e.preventDefault();
  window.location.href = "/";
};

function ResetPassword(props) {
  let queryParameters = window.location.search;
  if (!queryParameters || queryParameters === "") {
    queryParameters = "?lang=en";
  }
  let languageCode = queryParameters.split("=")[1];
  const schema = Yup.object().shape({
    password: baseSchema(Language).requiredStringPassword,
    confirmPassword: baseSchema(Language).requiredStringPassword.oneOf(
      [Yup.ref("password"), null],
      Language.translate("Passwords are not identical")
    ),
  });
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      return true;
    },
  });

  const pageData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const [resetButtontext, setResetButtontext] = useState(
    translateText("Reset Password")
  );
  const [passwordResetComplete, setPasswordResetComplete] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [holdTheDisplay, setHoldTheDisplay] = useState(true);

  const { token } = useParams();

  useEffect(() => {
    //validating the token
    fetch(Utils.apiUrlPrefix + "/auth/reset_password/" + token, {
      method: "get",
    })
      .then((res) => {
        setTokenError(res.status !== 200); //setting the token error, so nothing else works
        setHoldTheDisplay(false);
      })
      .catch((e) => console.log(e));
    document.title =
      translateText("Reset Password | TITAN BY NORTAC") +
      " " +
      Language.translate("Platform");
  }, []);

  const submitResetPassword = () => {
    log.info("Resetting the password");
    return fetch(Utils.apiUrlPrefix + "/auth/reset_password/new", {
      method: "post",
      headers: { "Content-Type": "application/json", "x-access-token": token },
      body: JSON.stringify({ password: pageData.password }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        log.info("Reset password", data);
        let errorResetting = false;
        if (data.message) {
          errorResetting = false;
        } else if (data.error_info.name) {
          errorResetting = true;
        }

        if (errorResetting) {
          setResetButtontext(translateText("Reset Password"));
          return Promise.resolve();
        }
        setPasswordResetComplete(true);
        return Promise.resolve();
      })
      .catch((e) => log.info(e));
  };

  function translateText(str) {
    // return str;
    return Language.translateLogin(str, languageCode);
  }

  //function to handle signin button click
  const handleSignIn = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    setResetButtontext(translateText("Checking"));
    submitResetPassword();
  };

  return (
    <div className="login">
      <Logo translateText={translateText}></Logo>
      {holdTheDisplay ? (
        <Grid
          container
          item
          className="messageBox"
          spacing={0}
          direction="column"
          align="center"
          justify="center"
        >
          <CircularProgress size="3rem" thickness={5} />
        </Grid>
      ) : tokenError ? (
        <Grid container direction="column" spacing={2} className="messageBox">
          <Grid item>
            <p className="message message-failure">
              <h2>{translateText("Oops")}!</h2>
              <h4>404 {translateText("Not Found")}</h4>
              {translateText(
                "Sorry! an error has occured. Requested page not found."
              )}
            </p>
            <br />
            <br />
          </Grid>
        </Grid>
      ) : passwordResetComplete ? (
        <form className="loginForm">
          <Grid container direction="column">
            <Grid item>
              <p className="message message-success">
                <h4>
                  {translateText("You Have Successfully Reset Your Password")}
                </h4>
              </p>
            </Grid>
            <Grid item>
              <CustomButton
                fullWidth
                size="large"
                color="command"
                onClick={goToTitan}
                variant="contained"
                type="submit"
              >
                {translateText("Login to TITAN BY NORTAC")}
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      ) : (
        <form
          className="loginForm"
          id="loginPageForm"
          name="logForm"
          noValidate
          autoComplete="off"
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <h4>{translateText("Reset Password")}</h4>
              {/* <div align='right'>* {translateText("Required fields")}</div> */}
            </Grid>
            <Grid item>
              <CustomTextField
                type="password"
                name="password"
                fullWidth
                placeholder={translateText("Password")}
                value={pageData.password}
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item>
              <CustomTextField
                type="password"
                name="confirmPassword"
                fullWidth
                placeholder={translateText("Confirm Password")}
                value={pageData.confirmPassword}
                onChange={handleFormInput}
                onBlur={handleFormBlur}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
            </Grid>
            <Grid item>
              <CustomButton
                fullWidth
                size="large"
                color="command"
                id="btnSubmit"
                disabled={
                  !(pageData.password === pageData.confirmPassword) ||
                  pageData.password === "" ||
                  pageData.confirmPassword === ""
                }
                onClick={handleSignIn}
                variant="contained"
                type="submit"
              >
                {resetButtontext}
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      )}
      <div className="copyright">
        {translateText("All Rights Reserved © 2021 NORTAC DEFENCE™")}
      </div>
    </div>
  );
}

export default ResetPassword;
