// External
import _ from "lodash";

// Internal
import { default as Module } from "sccModule";
import Language from "sccLanguage";
import Permission from "sccPermission";
import Group from "sccGroup";
import Utils from "sccUtils";
import TimeUtils from "sccTimeUtils";
import log from "loglevel";
import "../style/device_style.scss";

/**
 * The base class for Devices
 *
 * @class DeviceModule
 */
class DeviceModule extends Module.Module {
  constructor() {
    var options = {
      moduleName: "device",
      getterSetter: ["SyncInfo", "DeviceType", "NonReportThreshold"],
    };

    super(options);
    this.setNonReportThreshold({
      9999999999999: { value: 9999999999999, type: "NONE" },
      900000: { value: 900000, title: "15", type: "Minutes" },
      1800000: { value: 1800000, title: "30", type: "Minutes" },
      3600000: { value: 3600000, title: "60", type: "Minutes" },
      7200000: { value: 7200000, title: "2", type: "Hours" },
      14400000: { value: 14400000, title: "4", type: "Hours" },
      28800000: { value: 28800000, title: "8", type: "Hours" },
      57600000: { value: 57600000, title: "12", type: "Hours" },
      115200000: { value: 115200000, title: "24", type: "Hours" },
      230400000: { value: 230400000, title: "48", type: "Hours" },
      460800000: { value: 460800000, title: "72", type: "Hours" },
    });

    this.gatewayReportRate = {
      60000: { value: "1 min", title: "1", type: "Minute" },
      120000: { value: "2 min", title: "2", type: "Minutes" },
      300000: { value: "5 min", title: "5", type: "Minutes" },
      600000: { value: "10 min", title: "10", type: "Minutes" },
      900000: { value: "15 min", title: "15", type: "Minutes" },
      1800000: { value: "30 min", title: "30", type: "Minutes" },
      3600000: { value: "1 hour", title: "1", type: "Hour" },
      7200000: { value: "2 hour", title: "2", type: "Hours" },
      21600000: { value: "6 hour", title: "6", type: "Hours" },
      43200000: { value: "12 hour", title: "12", type: "Hours" },
      86400000: { value: "24 hour", title: "24", type: "Hours" },
    };

    this.gatewayEmergencyReportRate = {
      30000: { value: "30 sec", title: "30", type: "Seconds" },
      60000: { value: "1 min", title: "1", type: "Minute" },
      120000: { value: "2 min", title: "2", type: "Minutes" },
      300000: { value: "5 min", title: "5", type: "Minutes" },
      600000: { value: "10 min", title: "10", type: "Minutes" },
      900000: { value: "15 min", title: "15", type: "Minutes" },
      1800000: { value: "30 min", title: "30", type: "Minutes" },
      3600000: { value: "1 hour", title: "1", type: "Hour" },
      7200000: { value: "2 hours", title: "2", type: "Hours" },
      21600000: { value: "6 hours", title: "6", type: "Hours" },
      43200000: { value: "12 hours", title: "12", type: "Hours" },
    };

    this.hermesDevices = [
      "Sentry H6120 BM",
      "Sentry H6110 MP",
      "SAT-COM Leopard1",
      "Trellisware TSM TW-950",
    ];

    this.confirmBoxMsg1 =
      Language.translate(
        "Are you sure you want to remove all data from the device?"
      ) +
      "<br><br>" +
      Language.translate(
        "If applicable&#44; after device reset any applied encryption keys will need to be reconfigured between the device and the platform."
      );
    this.confirmBoxMsg2 = Language.translate(
      "Please enter your login password to continue"
    );

    // this.pendingClearData = false;
    // this.clearDataButtonText = Language.translate("Clear Data");
  }

  add(obj) {
    // only groups can be added from the device menu
    return Group.add(obj);
  }

  update(obj) {
    if (obj.imei) {
      // editing device object
      return Module.Module.prototype.update.call(this, obj);
    } else {
      // editing group object
      return Group.update(obj);
    }
  }

  delete(obj) {
    // only groups can be deleted from the device menu
    return Group.delete(obj);
  }

  onSocket(event, data) {
    const staticThis = this;
    var refreshGateway = false;
    var hasLocation = false;
    super.onSocket(event, data);

    if (Permission.verify("hermes_gateways", "view")) {
      const HermesGateways = require("sccHermesGateways").default;
      HermesGateways.onSocket(event, data);
    }
    //const GeofenceMap= require("sccGeofenceMap");
    //GeofenceMap.refresh(data);

    /*
			The following logic aims to prevent map from refershing if a device
			that has not reported within the users report age threshold is updated
			(Track bug 3821)
		*/
    const UserSetting = require("sccUserSetting").default;
    const TimeUtils = require("sccTimeUtils");

    const reportAge = UserSetting.get("report_age");
    const now = TimeUtils.getTimestamp();

    const lastReportedTime = now - data.report_timestamp;
    const reportAgeSeconds = reportAge * 60 * 60;

    if (
      !_.isNull(data.longitude) &&
      !_.isNull(data.latitude) &&
      !_.isUndefined(data.longitude) &&
      !_.isUndefined(data.latitude)
    ) {
      hasLocation = true;
      if (
        staticThis.getDeviceMode(data) == "Gateway Device" &&
        event.split(":")[0] == "put"
      ) {
        refreshGateway = true;
      }
    }

    //Checking for a NaN coz devices edited without any history of lat/long, the system crashes
    if (
      (reportAge == -1 ||
        lastReportedTime < reportAgeSeconds ||
        refreshGateway) &&
      hasLocation &&
      !isNaN(lastReportedTime)
    ) {
      const DeviceOverlay = require("sccDeviceOverlay").default;
      const {
        default: DeviceDataDisplay,
      } = require("../../device/components/DataDisplay");
      DeviceDataDisplay.updateFeatureSelected();
      DeviceOverlay.refresh(data);
    }
  }

  onSocketDelete(url, data) {
    const DeviceOverlay = require("sccDeviceOverlay").default;
    DeviceOverlay.refresh(null, data);
    var device = this.get(data.id);
    device.kill && device.kill(true);
    Module.Module.prototype.onSocketDelete.call(this, url, data);
    const {
      default: DeviceDataDisplay,
    } = require("../../device/components/DataDisplay");
    DeviceDataDisplay.updateFeatureSelected();
  }

  setData(data, value, keys, merge) {
    merge = merge === false ? false : true; // would merge objects if merge is true or null

    if (!keys) {
      data = value || {};
      return data;
    }

    keys = _.concat([], keys); // convert to an array

    // holding the last key
    var lastKey = keys.pop();

    // getting the last object in the tree
    var obj = this.searchKeysIn(data, keys);

    if (value == null) {
      delete obj[lastKey];
    } else {
      if (merge && _.isObject(value) && _.has(obj, lastKey)) {
        // merge if object or array and key exists
        if (!_.isNull(value.parent_id) && !_.isUndefined(value.parent_id)) {
          obj[lastKey].parent_id = value.parent_id;
        }
        _.assign(obj[lastKey], value);
      } else {
        // assignment if a value type
        obj[lastKey] = value;
      }
    }

    return data;
  }

  getSyncDevices() {
    var devices = [];
    var tacticalDevices = _.map(_.filter(this.get(), { mode: 3 }), "id");
    var atakDevices = _.map(_.filter(this.get(), { mode: 9 }), "id");
    var uaDevices = _.map(_.filter(this.get(), { type: "NORTAC Orion" }), "id");

    devices = _.union(tacticalDevices, uaDevices, atakDevices); // List of distinct device ids from the two sets, in case UA Devices gain tactical option in the future.

    return devices;
  }

  getReportedDevices() {
    const UserSetting = require("sccUserSetting").default;
    const Device = require("sccDevice").default;
    let reportAge = UserSetting.get("report_age");

    let devices = this.get();

    // if report age is 'None' show all devices
    if (reportAge !== -1) {
      // getting report age in seconds
      reportAge = reportAge * 60 * 60;

      devices = _.omitBy(Device.get(), function (device) {
        var now = TimeUtils.getTimestamp();
        return now - device.report_timestamp > reportAge;
      });
    }

    devices = _.omitBy(devices, (device) => {
      return device.longitude == null || device.latitude == null;
    });

    return _.values(devices);
  }
}

/**
 * Overrides the loadData to add assetId to all devices.
 * This is to make sure old code that use assetId instead of imei works
 * until we revise it.
 */
DeviceModule.prototype.loadData = function () {
  var $this = this;
  return Module.Module.prototype.loadData.call(this).then(function (devices) {
    _.each(devices, function (device) {
      device.assetId = device.imei;
      device.type = $this.getDeviceType(device.type_id).title;
    });
    return devices;
  });
};

/**
 * submits the sync all command for tactical devices to the back-end
 */
DeviceModule.prototype.submitSyncAll = function () {
  var options = {
    url: this.routeUrl + "/sync",
    method: "POST",
    data: {},
  };
  return Utils.httpRequestHandler(options);
};

DeviceModule.prototype.getGatewayReportRate = function () {
  return this.gatewayReportRate;
};

DeviceModule.prototype.updateGatewayDevices = function () {
  const staticThis = this;
  var array = _.filter(this.get(), function (device) {
    return staticThis.getDeviceMode(device) == "Gateway Device";
  });
  this.gatewayDeviceList = array;
  return;
};

DeviceModule.prototype.getGatewayEmergencyReportRate = function () {
  return this.gatewayEmergencyReportRate;
};

DeviceModule.prototype.getHermesDevices = function () {
  return this.hermesDevices;
};

/**
 * loads the device sync info status from the DB
 *
 * @return {Object} DB response object containing sync information
 */
DeviceModule.prototype.loadSyncInfo = function () {
  var options = {
    url: this.routeUrl + "/sync",
    method: "GET",
  };
  return Utils.httpRequestHandler(options);
};

DeviceModule.prototype.generateAes256 = function () {
  var options = {
    url: this.routeUrl + "/aes256",
    method: "GET",
  };

  return Utils.httpRequestHandler(options).then(function (response) {
    return response.data.result.key;
  });
};

/**
 * loads the device type info from the DB
 *
 * @return {Object} DB response object containing device type information
 */
DeviceModule.prototype.loadDeviceType = function () {
  var options = {
    url: this.routeUrl + "/type",
    method: "GET",
  };
  return Utils.httpRequestHandler(options);
};

DeviceModule.prototype.onSocketUpdate = function (url, data) {
  //refreshes edit object display after poll settings
  //
  //left code as in-progress:
  // const DeviceMenu= require("sccDeviceMenu");
  // var editObj = DeviceMenu.getEditObj();
  // if(editObj && editObj.id === data.id){
  // 	  editObj.settings = data.settings;
  // 	  DeviceMenu.setUaValues(editObj);
  // }

  //First attempt at report/alert validation(does not work)
  //
  // const Index= require("../scripts/index.js");
  // Index.editObj.setNonReport();

  Module.Module.prototype.onSocketUpdate.call(this, url, data);
  DeviceModule.prototype.rebuildGroupTree(data);
};

DeviceModule.prototype.onSocketAdd = function (url, data) {
  Module.Module.prototype.onSocketAdd.call(this, url, data);
  DeviceModule.prototype.rebuildGroupTree(data);
};

DeviceModule.prototype.rebuildGroupTree = function (data) {
  var deviceId = data.id;
  var groupIds = data.groups;
  _.each(Group.get(), function (group) {
    // no need to remove the device if group.id is in
    // the groups array recevice from socket
    if (_.indexOf(groupIds, group.id) > -1) return;

    _.remove(group.devices, function (dId) {
      return dId === deviceId;
    });
  });

  // adding the device to groups
  _.each(groupIds, function (groupId) {
    var group = Group.get(groupId);
    if (group) {
      group.devices = _.union(group.devices, [deviceId]);
    }
  });

  // rebuilding the tree structure
  Group.buildGroupTrees();
};

/**
 *  Delete SA data - 1st confirmation
 */
// DeviceModule.prototype.clearData = function(editObj){
// 	const $this = this;
// 	$this.pendingClearData = true;
// 	this.clearDataButtonText = Language.translate("Clearing Data");

// 	GuiUtils.confirmBox($this.confirmBoxMsg1)
// 		return $this.pollCommands(obj, "clear_data")
// 		.then(function(){
// 			//$this.pendingClearData = false;
// 		}).catch(function(error){
// 			log.debug("error:", error);
// 			// $this.pendingClearData = false;
// 			// $this.clearDataButtonText = Language.translate("Clear Data");
// 			Utils.notify({message: error.message, type: "error", title: error.name});
// 		});
// 	});
// };

/**
 *
 */
DeviceModule.prototype.pollCommands = function (device, command, settings) {
  if (!device.assetId) {
    device.assetId = device.imei;
  }

  var options;
  options = {
    url: Utils.apiUrlPrefix + "/device/command",
    body: {
      id: device.id,
      imei: device.assetId,
      comm_id: device.comm_id,
      command: command,
      settings_code: settings,
    },
    method: "PUT",
  };
  const DeviceMenu = require("sccDeviceMenu").default;
  if (device.type === "Hawkeye 5500") DeviceMenu.closeAfterHawkeyeConfig(); //close the edit menu

  return Utils.httpRequestHandler(options)
    .then(function () {
      if (device.type === "Hawkeye 5500")
        DeviceMenu.saveAfterHawkeyeConfig(settings); //save to db
    })
    .catch(function () {});
};

DeviceModule.prototype.deviceBatteryLevel = function (batteryLevel) {
  //	if(batteryLevel == null) return;
  if (batteryLevel === 0) {
    return "empty";
  } else if (batteryLevel < 30 && batteryLevel !== 0) {
    return "almost-empty";
  } else if (batteryLevel >= 30 && batteryLevel < 70) {
    return "half";
  } else if (batteryLevel >= 70 && batteryLevel < 100) {
    return "almost-full";
  } else if (batteryLevel === 100) {
    return "full";
  }
};

DeviceModule.prototype.showFirmware = function (editObj) {
  var firmware = this.get(editObj.id).poll_firmware + "";
  return firmware.indexOf(".") > -1 ? firmware : firmware + ".0";
};

DeviceModule.prototype.showClearData = function (obj) {
  return this.get(obj.id).reset_device_status === "ready";
};

//Check for device type and return
DeviceModule.prototype.checkIfConsignment = function (device, isSettings) {
  if (_.isNull(device) || _.isUndefined(device)) return;

  if (
    this.getDeviceType(device.type_id).title === "Container CCU" ||
    this.getDeviceType(device.type_id).title === "Container TAG" ||
    (this.getDeviceType(device.type_id).title === "Shadow" &&
      this.getDeviceType(device.type_id).modes[device.mode]?.title ===
        "Consignment" &&
      isSettings === false)
  ) {
    return true;
  } else {
    return false;
  }
};
DeviceModule.prototype.init = function () {
  var $this = this;
  if (!Permission.verify("device", "view")) {
    log.debug("User is not permitted for Device module");
    return Promise.resolve();
  }

  return $this
    .loadDeviceType()
    .then(function (deviceTypeResponse) {
      log.debug("DeviceType", deviceTypeResponse.data.result);
      $this.setDeviceType(deviceTypeResponse.data.result);
      return $this.loadSyncInfo();
    })
    .then(function (syncInfoResponse) {
      log.debug("SyncInfo", syncInfoResponse.data.result);
      $this.setSyncInfo(syncInfoResponse.data.result);
      return Promise.resolve();
    })
    .then(() => {
      return Module.Module.prototype.init.call(this);
    })
    .then(() => {
      const DeviceOverlay = require("sccDeviceOverlay").default;
      let oldReportedDevices = this.getReportedDevices().map(
        (device) => device.id
      );
      // check every 30 seconds, if device report age is out of report_age setting, refresh map
      setInterval(() => {
        const UserSetting = require("sccUserSetting").default;
        let reportAge = UserSetting.get("report_age");
        if (reportAge !== -1) {
          let newReportedDevices = this.getReportedDevices().map(
            (device) => device.id
          );

          if (!_.isEqual(oldReportedDevices, newReportedDevices)) {
            oldReportedDevices = newReportedDevices;
            DeviceOverlay.reset(true);
          }
        }
      }, 30000);
    });
};

DeviceModule.prototype.getDeviceMode = function (device) {
  if (_.isNull(device) || _.isUndefined(device)) return;
  return this.getDeviceType(device.type_id).modes[device.mode].title;
};

DeviceModule.prototype.getParentDevice = function (device) {
  if (_.isNull(device) || _.isUndefined(device)) {
    return null;
  } else {
    if (this.getDeviceMode(device) != "Standalone") {
      return null;
    }

    if (!_.isNull(device.parent_id) && !_.isUndefined(device.parent_id)) {
      if (
        !_.isNull(this.get(device.parent_id)) &&
        !_.isUndefined(this.get(device.parent_id))
      ) {
        return this.get(device.parent_id).name;
      }
      return null;
    } else if (!_.isNull(device.report) && !_.isUndefined(device.report)) {
      if (
        !_.isNull(device.report.parent_id) &&
        !_.isUndefined(device.report.parent_id)
      ) {
        if (
          !_.isNull(this.get(device.report.parent_id)) &&
          !_.isUndefined(this.get(device.report.parent_id))
        ) {
          return this.get(device.report.parent_id).name;
        }
        return null;
      }
      return null;
    }

    const HermesGateways = require("sccHermesGateways").default;
    var name;
    _.some(HermesGateways.get(), (hermesDevice) => {
      _.some(hermesDevice.reports, (report) => {
        if (report.id == device.report_id) {
          if (
            !_.isNull(this.get(report.parent_id)) &&
            !_.isUndefined(this.get(report.parent_id))
          ) {
            name = this.get(report.parent_id).name;
            return true;
          }
        }
      });
    });
    if (name) {
      return name;
    }

    return null;
  }
};

DeviceModule.prototype.getDeviceTypeObj = function (id) {
  return this.getDeviceType(id);
};

DeviceModule.prototype.getAvaiableDevices = function (devices) {
  let avaiableDevices = [];

  if (devices?.length > 0) {
    devices.forEach((deviceId) => {
      if (this.get(deviceId)) {
        avaiableDevices.push(deviceId);
      }
    });
  }

  return avaiableDevices;
};

export default new DeviceModule();
