import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import countries from "country-list";
import moment from "moment";
import { useFormikContext } from "formik";

// Material-UI
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, TextField, InputAdornment } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";

// Components
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomDropdown from "@Components/CustomDropdown/CustomDropdown";
import CustomSwitch from "@Components/CustomSwitch";
import CustomTextField from "@Components/CustomTextField";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import Client from "sccClient";
import Language from "sccLanguage";
import Maps from "sccMaps";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  expiryTimestamp: {
    float: "left",
  },
  textFieldDate: {
    width: "100%",
  },
  companyFeatures: {
    borderBottom: `1px solid ${theme.palette.colors.gray.dark}`,
    " & h1": {
      fontWeight: "bold",
      fontSize: "1.4rem",
    },
    "& .MuiChip-root": {
      backgroundColor: theme.palette.chips.pink.main,
      color: theme.palette.colors.white.main,
      height: "20px",
    },
    "& .saRequirementsTooltip": {
      "& h5": {
        backgroundColor: theme.palette.colors.gray.light,
        color: theme.palette.colors.black.main,
      },
      "& li": {
        backgroundColor: theme.palette.colors.white.main,
        color: theme.palette.colors.black.main,
      },
    },
  },
  countriesPaper: {
    maxHeight: 290,
    width: 320,
  },
  countriesUl: {
    paddingTop: 0,
  },
  selectedCountry: {
    backgroundColor: theme.palette.colors.gray.dark,
  },
  searchBox: {
    paddingLeft: 4,
    zIndex: 2,
    position: "sticky",
    top: 0,
    backgroundColor: `${theme.palette.colors.gray.main} !important`,
  },
}));

const twoFactorList = [
  { title: "", value: null },
  { title: "Disabled", value: 0 },
  { title: "Email", value: 1 },
  { title: "SMS", value: 2 },
  { title: "Email and SMS", value: 3 },
];

export default function ClientForm(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const maps = Object.values(Maps.get());
  const [features, setFeatures] = useState();
  const [checkedMapLayers, setCheckedMapLayers] = useState([]);
  const [checkedFeatures, setCheckedFeatures] = useState([]);

  const formik = useFormikContext();
  const moduleItemData = formik.values;
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;
  const [expiryDateCheckbox, setExpiryDateCheckbox] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  // for country search use
  const [searchedCountries, setSearchedCountries] = useState([]);
  const [searchCountryText, setSearchCountryText] = useState("");
  const [isCountryListOpen, setIsCountryListOpen] = useState(false);

  //  use debounce to avoid too many re-renders
  const handleCountriesSearch = _.debounce((value) => {
    const searchText = value;
    if (searchText !== "") {
      const newSearchedCountries = countries
        .getNames()
        .filter((country) =>
          country.toLowerCase().includes(searchText.toLowerCase())
        );
      setSearchedCountries(newSearchedCountries);
    } else {
      setSearchedCountries(countries.getNames());
    }
  }, 300);

  const onCountriesSearch = (e) => {
    setSearchCountryText(e.target.value);
    handleCountriesSearch(e.target.value);
  };

  const newClient = {
    active: false,
    company: "",
    city: "",
    country: "",
    email: "",
    start_moment: null,
    start_timestamp: null,
    expiry_timestamp: null,
    device_limit: null,
    user_limit: null,
    saFeatureSelected: null,
    "2fa": null,
    features: [1],
    map_layers: [],
    expiry_exists: true,
  };

  const handleTwoFactorAuthChange = (e) => {
    handleFormInput(e);
  };

  useEffect(() => {
    const clientData = mwState.moduleItemData;
    if (!features) {
      Client.getFeatures().then((features) => {
        const allFeatures = Object.values(features);
        setFeatures(allFeatures);
        clientData.saFeatureSelected =
          clientData.features?.length > 0 &&
          clientData.features.includes(
            _.find(allFeatures, { title: "Situational Awareness" }).id
          ); // as feature id is 6
        setMwState((p) => ({
          ...p,
          moduleItemData: { ...newClient, ...clientData },
          moduleItemDataOriginal: { ...newClient, ...clientData },
        }));

        setStartDate(
          moment.unix(clientData.start_timestamp).format("YYYY-MM-DD")
        );

        clientData.expiry_timestamp &&
          setEndDate(
            moment.unix(clientData.expiry_timestamp).format("YYYY-MM-DD")
          );
        setExpiryDateCheckbox(
          _.isNull(clientData.expiry_timestamp) ||
            _.isUndefined(clientData.expiry_timestamp)
        );
      });
    }

    setSearchedCountries(countries.getNames());
  }, []);

  useEffect(() => {
    if (!_.isUndefined(moduleItemData.map_layers)) {
      setCheckedMapLayers(moduleItemData.map_layers);
    }
    if (!_.isUndefined(moduleItemData.features)) {
      setCheckedFeatures(moduleItemData.features);
    }
  }, [moduleItemData]);

  const handleMapLayersToggle = (id) => {
    const currentIndex = checkedMapLayers.indexOf(id);
    const newChecked = [...checkedMapLayers];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedMapLayers(newChecked);
    formik.setFieldValue("map_layers", newChecked);
  };

  const handleFeaturesToggle = (id) => {
    const currentIndex = checkedFeatures.indexOf(id);
    const newChecked = [...checkedFeatures];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedFeatures(newChecked);
    formik.setFieldValue("features", newChecked);
    const checkSa =
      newChecked.indexOf(
        _.find(features, { title: "Situational Awareness" }).id
      ) !== -1;
    formik.setFieldValue("saFeatureSelected", checkSa);
  };

  const handleExpiryDateCheckBox = (e) => {
    setExpiryDateCheckbox(e.target.checked);

    if (e.target.checked === false) {
      formik.setFieldValue("expiry_exists", false);
    } else {
      formik.setFieldValue("expiry_exists", true);
      formik.setFieldValue("expiry_timestamp", null);
    }
  };

  // DATES
  const handleClientDate = (e) => {
    if (e.target.name === "start_timestamp") setStartDate(e.target.value);
    if (e.target.name === "expiry_timestamp") setEndDate(e.target.value);
    formik.setFieldValue(e.target.name, moment(e.target.value).unix());
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <Grid container spacing={12}>
          <Grid item xs={12} className="switchWrap">
            <CustomSwitch
              label2={
                moduleItemData.active
                  ? Language.translate("active").toUpperCase()
                  : Language.translate("inactive").toUpperCase()
              }
              name="active"
              value={Boolean(moduleItemData.active)}
              onChange={handleFormInput}
              checked={Boolean(moduleItemData.active)}
              color="primary"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
        <Grid container spacing={12}>
          <Grid item xs={12} className="pullRight">
            {`* ${Language.translate("Required fields")}`}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CustomTextField
              label={`${Language.translate("Company Name")}*`}
              name="company"
              value={moduleItemData.company}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.company && Boolean(formik.errors.company)}
              helperText={Language.translate(
                formik.touched.company && formik.errors.company
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <CustomTextField
              label={`${Language.translate("City")}*`}
              name="city"
              value={moduleItemData.city}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={Language.translate(
                formik.touched.city && formik.errors.city
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomDropdown
              label={`${Language.translate("Country")}*`}
              name="country"
              value={String(moduleItemData.country)}
              onChange={(e) => {
                e.stopPropagation();
                e.target.value && handleFormInput(e);
              }}
              SelectProps={{
                open: isCountryListOpen,
                onOpen: () => setIsCountryListOpen(true),
                onClose: () => {
                  setIsCountryListOpen(false);
                  setSearchCountryText("");
                  handleCountriesSearch("");
                },

                MenuProps: {
                  autoFocus: false,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  classes: {
                    paper: classes.countriesPaper,
                    list: classes.countriesUl,
                  },
                },
              }}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={Language.translate(
                formik.touched.country && formik.errors.country
              )}
            >
              <MenuItem
                value=""
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCountryListOpen(true);
                }}
                className={classes.searchBox}
              >
                <TextField
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder={Language.translate("Search")}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onCountriesSearch(e);
                  }}
                  value={searchCountryText}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </MenuItem>
              {searchedCountries.sort(Intl.Collator().compare).map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  className={
                    option === moduleItemData.country && classes.selectedCountry
                  }
                >
                  {option}
                </MenuItem>
              ))}
            </CustomDropdown>
          </Grid>
        </Grid>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <CustomTextField
              label={`${Language.translate("Email")}*`}
              name="email"
              value={moduleItemData.email}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={Language.translate(
                formik.touched.email && formik.errors.email
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={12} className={classes.expiryTimestamp}>
          <Grid item xs={6}>
            <CustomCheckbox
              label={Language.translate("No Expiry Date")}
              name="expiry_exists"
              onChange={(e) => handleExpiryDateCheckBox(e)}
              color="primary"
              checked={expiryDateCheckbox}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <CustomTextField
              id="date"
              type="date"
              className={classes.textFieldDate}
              label={`${Language.translate("Start Date")}*`}
              name="start_timestamp"
              defaultValue={startDate}
              value={startDate}
              inputProps={{ max: endDate }}
              onChange={handleClientDate}
              error={
                formik.touched.start_timestamp &&
                Boolean(formik.errors.start_timestamp)
              }
              helperText={Language.translate(
                formik.touched.start_timestamp && formik.errors.start_timestamp
              )}
            />
          </Grid>
          <Grid item xs={6}>
            {!expiryDateCheckbox && (
              <CustomTextField
                id="date"
                name="expiry_timestamp"
                className="textFieldDate"
                label={`${Language.translate("End Date")}*`}
                type="date"
                sx={{ width: 250 }}
                defaultValue={endDate}
                value={endDate}
                inputProps={{ min: startDate }}
                onChange={handleClientDate}
                error={
                  formik.touched.expiry_timestamp &&
                  Boolean(formik.errors.expiry_timestamp)
                }
                helperText={Language.translate(
                  formik.touched.expiry_timestamp &&
                    formik.errors.expiry_timestamp
                )}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <CustomTextField
              type="number"
              className={classes.textFieldDate}
              value={moduleItemData.device_limit}
              label={`${Language.translate("Device Limit")}*`}
              name="device_limit"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={
                formik.touched.device_limit &&
                Boolean(formik.errors.device_limit)
              }
              helperText={Language.translate(
                formik.touched.device_limit && formik.errors.device_limit
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              type="number"
              label={`${Language.translate("User Limit")}*`}
              name="user_limit"
              value={moduleItemData.user_limit}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={
                formik.touched.user_limit && Boolean(formik.errors.user_limit)
              }
              helperText={Language.translate(
                formik.touched.user_limit && formik.errors.user_limit
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <CustomDropdown
              label={`${Language.translate("Two Factor Authentication")}*`}
              name="2fa"
              value={String(moduleItemData["2fa"])}
              onChange={handleTwoFactorAuthChange}
              onBlur={handleFormBlur}
              error={formik.touched["2fa"] && Boolean(formik.errors["2fa"])}
              helperText={Language.translate(
                formik.touched["2fa"] && formik.errors["2fa"]
              )}
            >
              {twoFactorList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </CustomDropdown>
          </Grid>
        </Grid>
        <Grid container spacing={12}>
          <Grid item xs={12} className={classes.companyFeatures}>
            <h1>
              {`${Language.translate("Map Layers")}*  `}
              {formik.errors.map_layers && (
                <Chip
                  label={Language.translate(formik.errors.map_layers)}
                  size="small"
                />
              )}
            </h1>
            {maps.map((item) => (
              <CustomCheckbox
                key={`mapLayers-${item.id}`}
                label={item.title}
                name="map_layers"
                onChange={() => handleMapLayersToggle(item.id)}
                color="primary"
                checked={checkedMapLayers.indexOf(item.id) !== -1}
              />
            ))}
          </Grid>
        </Grid>
        <Grid container spacing={12}>
          <Grid item xs={12} className={classes.companyFeatures}>
            <h1>{Language.translate("Features")}</h1>

            {features &&
              features.map((item) => (
                <CustomCheckbox
                  key={`mapFeatures-${item.id}`}
                  label={item.title}
                  name="features"
                  color="primary"
                  disabled={item.title === "Asset Tracking"}
                  onChange={() => handleFeaturesToggle(item.id)}
                  checked={checkedFeatures.indexOf(item.id) !== -1}
                />
              ))}
          </Grid>
        </Grid>
        {features &&
          checkedFeatures.indexOf(
            _.find(features, { title: "Situational Awareness" }).id
          ) !== -1 && (
            <div className={classes.companyFeatures}>
              <h1>
                {Language.translate("Situational Awareness")}
                <CustomTooltip
                  className={classes.saRequirementsTooltip}
                  title={
                    <div>
                      <h5>{Language.translate("Requirements")}</h5>
                      <Divider />
                      <ul>
                        <li>
                          {Language.translate(
                            "The maximum number of Wave devices and SCCT devices permitted to use SA per client."
                          )}
                        </li>
                      </ul>
                    </div>
                  }
                  placement="top"
                >
                  <IconButton
                    edge="end"
                    aria-label="situational-awareness-info"
                  >
                    <InfoIcon fontSize="medium" />
                  </IconButton>
                </CustomTooltip>
                {!(formik.values.sa_wave_limit > 0) &&
                  !(formik.values.sa_scct_limit > 0) && (
                    <Chip
                      label={` ${Language.translate(
                        "Select at least 1 Wave or SCCT!"
                      )}`}
                      size="small"
                    />
                  )}
              </h1>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <CustomTextField
                    type="number"
                    label={Language.translate("Wave Limit")}
                    name="sa_wave_limit"
                    value={moduleItemData.sa_wave_limit}
                    onChange={handleFormInput}
                    onBlur={handleFormBlur}
                    error={
                      formik.touched.sa_wave_limit &&
                      Boolean(formik.errors.sa_wave_limit)
                    }
                    helperText={Language.translate(
                      formik.touched.sa_wave_limit &&
                        formik.errors.sa_wave_limit
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    type="number"
                    label={Language.translate("SCCT Limit")}
                    name="sa_scct_limit"
                    value={moduleItemData.sa_scct_limit}
                    onChange={handleFormInput}
                    onBlur={handleFormBlur}
                    error={
                      formik.touched.sa_scct_limit &&
                      Boolean(formik.errors.sa_scct_limit)
                    }
                    helperText={Language.translate(
                      formik.touched.sa_scct_limit &&
                        formik.errors.sa_scct_limit
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          )}
      </div>
    </React.Fragment>
  );
}
