import React, { useState, useContext, useEffect } from "react";

// External
import _ from "lodash";
import { useFormikContext } from "formik";

// Material-UI
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

// Components
import CustomAssetWrapper from "@Components/CustomAssetWrapper";
import { CustomAssetSelectorContext } from "@Components/CustomAssetWrapper/CustomAssetSelectorContext";
import CustomCheckbox from "@Components/CustomCheckbox";
import CustomInputAdornment from "@Components/CustomInputAdornment";
import CustomItemCount from "@Components/CustomItemCount";
import CustomListItemCollapse from "@Components/CustomListItemCollapse";
import CustomTextField from "@Components/CustomTextField";
import CustomTooltip from "@Components/CustomTooltip";
import { ModuleWrapperContext } from "@Components/ModuleWrapper/ModuleWrapperContext";

// Styles
import { moduleForm } from "@Styles/ModuleForm";

// Internal
import Device from "sccDevice";
import Language from "sccLanguage";
import Profile from "sccProfile";
import Sa from "sccSa";
import SaMenu from "sccSaMenu";
import Utils from "sccUtils";

const useStyles = makeStyles((theme) => ({
  ...moduleForm(theme),
  itemName: {
    display: "inline-flex",
    maxWidth: 90,
    width: 90,
    overflow: "hidden",
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  chipPadding: {
    paddingLeft: 5,
  },
  unitsLabel: {
    padding: "6px 8px",
    backgroundColor: theme.palette.colors.gray.dark,
    border: `1px solid ${theme.palette.colors.gray.dark}`,
    fontSize: "0.9rem",
  },
  specialError: {
    color: "#f44336",
    fontSize: "1rem",
    marginLeft: 10,
    marginTop: 5,
    marginBottom: 5,
    textAlign: "center",
  },
  noResult: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const MW_ADDEDIT_MODE = process.env.REACT_APP_MW_ADDEDIT_MODE;

export default function SAForm(props) {
  const [mwState, setMwState] = useContext(ModuleWrapperContext);
  const formik = useFormikContext();
  const moduleItemData = formik.values;

  const [assetSelectorState] = useContext(CustomAssetSelectorContext);
  const [membersCount, setMembersCount] = useState(0);
  const [subscriberCount, setSubscriberCount] = useState(0);

  //search code for subscribers list
  const arraySubscribersList = getSubscribersList();
  const [search, setSearch] = useState("");
  const [filteredArraySubscribersList, setFilteredArraySubscribersList] =
    useState([]);
  const handleClearSearch = () => {
    setSearch("");
    setFilteredArraySubscribersList(arraySubscribersList);
  };

  function updateFilteredData(data) {
    setFilteredArraySubscribersList(data);
  }

  useEffect(() => {
    const data = arraySubscribersList;
    const filteredDataArr = data
      ? data.filter((id) => {
          let result = false;
          const item = Device.get(id);
          if (item && item.name.toLowerCase().includes(search.toLowerCase())) {
            result = true;
          }
          return result;
        })
      : data;
    updateFilteredData(filteredDataArr);
  }, [search]);

  const [checkedSyncDevices, setCheckedSyncDevices] = useState([]);

  //to be used in add edit
  const handleFormInput = formik.handleChange;
  const handleFormBlur = formik.handleBlur;

  const handleFormInputCheck = (e) => {
    formik.setFieldValue(
      "subscribers.devices",
      _.xor(checkedSyncDevices, [Number(e.target.value)])
    );
  };
  const minimizeSelection = assetSelectorState.minimizeSelection;

  const moduleItemDataSA = {
    title: "",
    interval: null,
    distance: null,
    members: {
      groups: [],
      devices: [],
    },
    subscribers: {
      groups: [],
      devices: [],
    },
  };

  const verifySaSubscriberLimitNotExceeded = function () {
    // Get total allowable
    var waveLimit = Profile.get().client.sa_wave_limit;
    var scctLimit = Profile.get().client.sa_scct_limit;

    // Remove previously used and currently selected
    const devices = Device.get();
    var previouslySelected = getAllSaDevicesSelected();
    var currentlySelected = moduleItemData.subscribers.devices;
    var current = _.union(previouslySelected, currentlySelected);

    _.each(devices, (device) => {
      if (
        SaMenu.isDeviceAnSaSubscriber(device.id) ||
        current.indexOf(device.id) > -1
      ) {
        if (device.type === "Wave") {
          waveLimit--;
        }
        if (device.mode === 3) {
          scctLimit--;
        }
      }

      if (
        previouslySelected.indexOf(device.id) > -1 &&
        currentlySelected.indexOf(device.id) < 0
      ) {
        if (device.type === "Wave") {
          waveLimit++;
        }
        if (device.mode === 3) {
          scctLimit++;
        }
      }
    });
    var invalid = waveLimit < 0 || scctLimit < 0;
    return invalid;
  };

  /*
   * Return all the devices from SA object and add them to array
   */
  function getAllSaDevicesSelected() {
    return SaMenu.getAllSaDevicesSelected();
  }

  /*
   * Return all the devices that can be utilised for SA
   */
  function getSubscribersList() {
    let saSubscribers = [];
    if (moduleItemData.id) {
      saSubscribers = Sa.get(moduleItemData.id).subscribers.devices;
    }
    const devices = Device.get();
    const arr = [];
    // Get total devices used in SA Rules
    const saDevices = getAllSaDevicesSelected();
    // Remove this specific Sa rule devices from total Sa devices
    const currentSaDevices = _.xor(saSubscribers, saDevices);

    // Get total allowable
    var waveLimit = Profile.get().client.sa_wave_limit;
    var scctLimit = Profile.get().client.sa_scct_limit;

    // Get all the devices eligible to be SA subscribers and push to new array
    _.each(devices, (device) => {
      // Filter: devices in mode 3 AND device types Whisper in mode 0
      if (
        (device.mode === 3 && device.type !== "Wave" && scctLimit > 0) ||
        (device.mode === 3 &&
          device.type === "Wave" &&
          scctLimit > 0 &&
          waveLimit > 0) ||
        (device.mode === 0 && device.type === "Wave" && waveLimit > 0)
      ) {
        arr.push(device.id);
      }
    });
    // Remove all devices that already belong to an SA rule from new array
    return _.xor(arr, currentSaDevices);
  }

  useEffect(() => {
    const data = arraySubscribersList;
    const filteredDataArr = data
      ? data.filter((id) => {
          let result = false;
          const item = Device.get(id);
          if (item && item.name.toLowerCase().includes(search.toLowerCase())) {
            result = true;
          }
          return result;
        })
      : data;
    updateFilteredData(filteredDataArr);
  }, [search]);

  useEffect(() => {
    if (mwState.wrapperDisplayMode === MW_ADDEDIT_MODE) {
      setMwState((p) => ({
        ...p,
        moduleItemData: {
          ...moduleItemDataSA,
          ...mwState.moduleItemData,
          membersCount,
          subscriberSelectionInValid: false,
          members: {
            groups: p.moduleItemData.members
              ? [...p.moduleItemData.members.groups.sort()]
              : [],
            devices: p.moduleItemData.members
              ? [...p.moduleItemData.members.devices.sort()]
              : [],
          },
        },
        moduleItemDataOriginal: {
          ...moduleItemDataSA,
          ...mwState.moduleItemData,
          membersCount,
          subscriberSelectionInValid: false,
          members: {
            groups: p.moduleItemData.members
              ? [...p.moduleItemData.members.groups.sort()]
              : [],
            devices: p.moduleItemData.members
              ? [...p.moduleItemData.members.devices.sort()]
              : [],
          },
        },
      }));
    }
  }, [mwState.wrapperDisplayMode]);

  //this code is used as a fix for the late update of moduleItemData.
  //Pending further investigation this works as required
  //this would get invoked only once at the time of pageload, so should not be an issue
  useEffect(() => {
    updateFilteredData(arraySubscribersList);
    if (moduleItemData.subscribers) {
      setMwState((p) => ({
        ...p,
        moduleItemData: {
          ...mwState.moduleItemData,
          membersCount: assetSelectorState.selection["members"].devices.length,
          subscriberSelectionInValid: verifySaSubscriberLimitNotExceeded(),
        },
        moduleItemDataOriginal: {
          ...mwState.moduleItemData,
          membersCount: assetSelectorState.selection["members"].devices.length,
          subscriberSelectionInValid: verifySaSubscriberLimitNotExceeded(),
        },
      }));
    }
  }, [moduleItemData.id]);

  useEffect(() => {
    moduleItemData.subscribers &&
      setCheckedSyncDevices(moduleItemData.subscribers.devices);
    if (!_.isEmpty(moduleItemData.subscribers)) {
      setSubscriberCount(
        Device.getAvaiableDevices(moduleItemData.subscribers.devices).length
      );
      formik.setFieldValue(
        "subscriberSelectionInValid",
        verifySaSubscriberLimitNotExceeded()
      ); //added just for validation purpose
    }
  }, [moduleItemData.subscribers]);

  useEffect(() => {
    if (assetSelectorState.selection["members"]) {
      formik.setFieldValue(
        "members",
        minimizeSelection(assetSelectorState.selection["members"])
      );
      if (!_.isEmpty(moduleItemData.members)) {
        setMembersCount(
          Device.getAvaiableDevices(
            assetSelectorState.selection["members"].devices
          ).length
        );
        formik.setFieldValue(
          "membersCount",
          assetSelectorState.selection["members"].devices.length
        ); //added just for validation purpose
      }
    }
  }, [assetSelectorState.selection]);

  const getDeviceAndMode = (device) => {
    const deviceTypeFeatures = Device.getDeviceType(device.type_id);
    const mode = deviceTypeFeatures.modes[device.mode]?.title || "N/A";
    const title = deviceTypeFeatures.title;

    let deviceMode;
    let deviceTitle;
    let deviceInfo;

    if (mode === "Standalone" || mode === "Open Protocol") {
      deviceMode = Language.translate(mode);
    } else {
      deviceMode = mode;
    }

    if (title === "Wave") {
      deviceTitle = "NORTAC Wave";
    } else {
      deviceTitle = title;
    }

    deviceInfo = deviceTitle + " " + deviceMode;

    return deviceInfo;
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.formContainer}>
        <Grid container spacing={1}>
          <Grid container item className="itemCountWrap" xs={12}>
            <CustomItemCount
              title={Language.translate("Subscribers")}
              count={subscriberCount}
            />{" "}
            {/* count should be moduleItemData.subscribers.devices.length */}
            <CustomItemCount
              title={Language.translate("Members")}
              count={membersCount}
            />{" "}
            {/* count should be assetSelectorState.selectionDevices.length */}
          </Grid>
          <p className="pullRight">
            {`* ${Language.translate("Required fields")}`}
          </p>
        </Grid>
        <Grid container item direction="column" spacing={1}>
          <Grid item xs={12}>
            <CustomTextField
              label={`${Language.translate("Title")}*`}
              value={String(moduleItemData.title)}
              name="title"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={Language.translate(
                formik.touched.title && formik.errors.title
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <CustomTextField
              label={`${Language.translate("Interval")}*`}
              value={moduleItemData.interval}
              name="interval"
              type="number"
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position="end">
                    <CustomTooltip
                      placement="top"
                      title={
                        <React.Fragment>
                          <Typography>
                            <b>{Language.translate("Report Age")}</b>
                            <br />
                            <span>
                              {Utils.transformSecondsToHours(
                                moduleItemData.interval,
                                "hours"
                              )}{" "}
                              <b>{Language.translate("Hours")}</b>
                            </span>
                            <br />
                            <span>
                              {Utils.transformSecondsToHours(
                                moduleItemData.interval,
                                "minutes"
                              )}{" "}
                              <b>{Language.translate("Minutes")}</b>
                            </span>
                            <br />
                            <span>
                              {moduleItemData.interval}
                              <b>{Language.translate("Seconds")}</b>
                            </span>
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <span className={classes.unitsLabel}>
                        {Language.translate("Seconds").toUpperCase()}
                      </span>
                    </CustomTooltip>
                  </CustomInputAdornment>
                ),
              }}
              error={formik.touched.interval && Boolean(formik.errors.interval)}
              helperText={Language.translate(
                formik.touched.interval && formik.errors.interval
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={`${Language.translate("Distance")}*`}
              value={moduleItemData.distance}
              name="distance"
              type="number"
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position="end">
                    <CustomTooltip
                      placement="top"
                      title={
                        <React.Fragment>
                          <Typography>
                            <b>{Language.translate("Distance")}</b>
                            <br />
                            <span>
                              {Utils.transformMetresToKm(
                                moduleItemData.distance,
                                "Kilometres"
                              )}{" "}
                              <b>{Language.translate("Kilometers")}</b>
                            </span>
                            <br />
                            <span>
                              {moduleItemData.distance}{" "}
                              <b>{Language.translate("Meters")}</b>
                            </span>
                            <br />
                            <span>
                              {Utils.transformMetresToKm(
                                moduleItemData.distance,
                                "Feet"
                              )}{" "}
                              <b>{Language.translate("Feet")}</b>
                            </span>
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <span className={classes.unitsLabel}>
                        {Language.translate("Meters").toUpperCase()}
                      </span>
                    </CustomTooltip>
                  </CustomInputAdornment>
                ),
              }}
              onChange={handleFormInput}
              onBlur={handleFormBlur}
              error={formik.touched.distance && Boolean(formik.errors.distance)}
              helperText={Language.translate(
                formik.touched.distance && formik.errors.distance
              )}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={6} direction="column">
            <CustomListItemCollapse
              title={Language.translate("Subscribers").toUpperCase()}
              color="primary"
              collapse={true}
              count={subscriberCount}
            >
              {formik.values.subscriberSelectionInValid
                ? Language.translate(formik.errors.subscriberSelectionInValid)
                : null}
              <List style={{ maxHeight: 300, overflow: "auto" }}>
                {
                  // If devices are part of an SA list then it CANNOT be available in other SA lists.
                  // 1) get array of device id's of all selected SA subscriber devices
                  // 2) filter from SyncDevices to get available devices
                }
                <div className="filterSubscribers">
                  {arraySubscribersList.length > 10 && (
                    <CustomTextField
                      id="txtSearch"
                      name="search"
                      className="DeviceSearch"
                      value={search}
                      disableUnderline={true}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <CustomInputAdornment position="start">
                            <SearchIcon />
                          </CustomInputAdornment>
                        ),
                        endAdornment: (
                          <CustomInputAdornment
                            position="end"
                            id="clearSearch"
                            onClick={handleClearSearch}
                            style={{ cursor: "pointer" }}
                          >
                            <ClearIcon />
                          </CustomInputAdornment>
                        ),
                      }}
                    />
                  )}
                </div>
                {filteredArraySubscribersList.length > 0 ? (
                  filteredArraySubscribersList.map((id) => {
                    const item = Device.get(id);
                    if (item) {
                      return (
                        <ListItem key={item.id}>
                          <CustomCheckbox
                            name="deviceChecked"
                            onChange={handleFormInputCheck}
                            color="primary"
                            value={item.id}
                            checked={
                              checkedSyncDevices.indexOf(Number(item.id)) > -1
                            }
                          />
                          <CustomTooltip placement="right" title={item.name}>
                            <span className={classes.itemName}>
                              <p>{item.name}</p>
                            </span>
                          </CustomTooltip>

                          <span className={classes.chipPadding}>
                            <Chip label={getDeviceAndMode(item)} size="small" />
                          </span>
                        </ListItem>
                      );
                    }
                  })
                ) : (
                  <ListItem className={classes.noResult}>
                    {Language.translate("There are no devices in Subscribers")}
                  </ListItem>
                )}
              </List>
            </CustomListItemCollapse>
          </Grid>
          <Grid container item xs={6} direction="column">
            <CustomListItemCollapse
              title={Language.translate("Members").toUpperCase()}
              color="primary"
              collapse={true}
              count={membersCount}
            >
              {membersCount > 50 ? (
                <p className={classes.specialError}>
                  {Language.translate(formik.errors.membersCount)}
                </p>
              ) : null}
              <CustomAssetWrapper
                openMain={true}
                editMode={true}
                selection={
                  mwState.moduleItemData.members || { groups: [], devices: [] }
                }
                height={300}
                name="members"
                showDeviceSearchField={false}
              />
            </CustomListItemCollapse>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
